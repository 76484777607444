import React, { useEffect, useState } from 'react';
function ComingSoon() {
    const [countDownValue, setCountDownValue] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    function showTyping(){
        var TxtType = function(el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 3000;
            this.txt = '';
            this.tick();
            this.isDeleting = false;
        };
        TxtType.prototype.tick = function() {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];
            if (this.isDeleting) this.txt = fullTxt.substring(0, this.txt.length - 1);
            else this.txt = fullTxt.substring(0, this.txt.length + 1);
            this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
            var that = this;
            var delta = 200 - Math.random() * 100;
            if (this.isDeleting) delta /= 2; 
            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period;
                this.isDeleting = true;
            } 
            else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }
            setTimeout(function() {
                that.tick();
            }, delta);
        };
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = '[ "We are still working on our website.", "Stay tuned for updates!" ]';
            var period = 3000;
            if (toRotate) new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    function countDown() {
        var countDownDate = new Date("Sep 22, 2024 23:59:59").getTime();
        var x = setInterval(function() {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0) {
                clearInterval(x);
                setCountDownValue({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
            }
            else {
                setCountDownValue({
                    days,
                    hours,
                    minutes,
                    seconds
                });
            }
        }, 1000);
    }
    useEffect(() => {
        showTyping();
        countDown();
    }, []);
    return (
        <>
            <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                <div className="hero-container">
                    <h1>Coming Soon</h1>
                    <p>
                        <span className="typewrite"></span>
                    </p>
                </div>
                <div className="countdown d-flex justify-content-center">
                    <div>
                        <h3 className="count-days">{countDownValue.days}</h3>
                        <h4>Days</h4>
                    </div>
                    <div>
                        <h3 className="count-hours">{countDownValue.hours}</h3>
                        <h4>Hours</h4>
                    </div>
                    <div>
                        <h3 className="count-minutes">{countDownValue.minutes}</h3>
                        <h4>Minutes</h4>
                    </div>
                    <div>
                        <h3 className="count-seconds">{countDownValue.seconds}</h3>
                        <h4>Seconds</h4>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ComingSoon;