import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from '../App';
import ComingSoon from '../page/view/ComingSoon'
// import NotFoundView from '../page/view/NotFoundView'
function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<ComingSoon />} />
                    {/* <Route path="*" element={<NotFoundView />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default Router;